import React from "react"
import { Link } from "gatsby"
import Image from "../components/image"
import Drawer from "./drawer"

export default () => (
  <header className="px-2 py-4 relative">
    <div className="flex justify-between items-center container mx-auto">
      <div style={{ width: "200px" }} className="hover:opacity-50">
        <Link to={"/"}>
          <Image filename="logo.png" alt="merclimbのロゴ" />
        </Link>
      </div>
      <div className="hidden md:block">
        <Link to={"/repair/"} className="px-3 hover:text-red-700">
          ハードリペア
        </Link>
        <Link to={"/maintain/"} className="px-3 hover:text-red-700">
          メンテナンス
        </Link>
        <Link to={"/car-list/"} className="px-3 hover:text-red-700">
          車両販売
        </Link>
        <Link to={"/about/"} className="px-3 hover:text-red-700">
          会社概要
        </Link>
        <Link to={"/contact/"} className="px-3 hover:text-red-700">
          お問い合わせ
        </Link>
      </div>
    </div>
    <Drawer />
  </header>
)
