import React from "react"
import "../utils/global.css"
import Header from "../components/header"
import Footer from "../components/footer"

export default ({ children }) => (
  <div className="flex" style={{ flexFlow: "column", minHeight: "100vh" }}>
    <Header />
    <main style={{ flex: "1" }}>{children}</main>
    <Footer />
  </div>
)
