import React from "react"
import { Link } from "gatsby"
import Image from "../components/image"

export default () => (
  <div className="absolute md:hidden top-0 right-0">
    <div className="drawer">
      <input className="drawer__checkbox" id="drawerCheckbox" type="checkbox" />
      <label className="drawer__icon" htmlFor="drawerCheckbox">
        <span className="drawer__icon -parts" />
      </label>
      <label className="drawer__overlay" htmlFor="drawerCheckbox" />
      <nav className="drawer__menu pb-2">
        <div className="py-3 px-3" style={{ width: "200px" }}>
          <Link to={"/"}>
            <Image filename="logo.png" alt="merclimbのロゴ" />
          </Link>
        </div>
        <ul>
          <li className="border-b px-4 py-3 cursor-pointer">
            <Link to={"/repair/"}>ハードリペア</Link>
          </li>
          <li className="border-b px-4 py-3 cursor-pointer">
            <Link to={"/maintain/"}>メンテナンス</Link>
          </li>
          <li className="border-b px-4 py-3 cursor-pointer">
            <Link to={"/car-list/"}>車両販売</Link>
          </li>
          <li className="border-b px-4 py-3 cursor-pointer">
            <Link to={"/about/"}>会社概要</Link>
          </li>
          <li className="px-4 py-3 pb-1 cursor-pointer">
            <Link to={"/contact/"}>お問い合わせ</Link>
          </li>
        </ul>
        <small className="text-center px-4 text-gray-700 text-xs">
          Copyright (C) 2020 merclimb All Rights Reserved.
        </small>
      </nav>
    </div>
    <style
      dangerouslySetInnerHTML={{
        __html:
          '\n.drawer {\n  position: relative;\n}\n.drawer__checkbox {\n  display: none;\n}\n.drawer__icon {\n  cursor: pointer;\n  display: block;\n  width: 56px;\n  height: 60px;\n  position: fixed;\n  right: 0;\n}\n.drawer__icon.-parts, .drawer__icon.-parts:before, .drawer__icon.-parts:after {\n  background-color: #000;\n  display: block;\n  width: 26px;\n  height: 2px;\n  position: absolute;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  margin: auto;\n}\n.drawer__icon.-parts:before, .drawer__icon.-parts:after {\n  content: " ";\n}\n.drawer__icon.-parts:before {\n  top: 16px;\n}\n.drawer__icon.-parts:after {\n  top: -16px;\n}\n.drawer__overlay {\n  background: #000;\n  opacity: 0;\n  pointer-events: none;\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  top: 0;\n  right: 0;\n}\n.drawer__menu {\n  background: #ffffff;\n  color: #000;\n  max-width: 100%;\n  width: 320px;\n  height: object-fit;\n  overflow-y: auto;\n  -webkit-overflow-scrolling: touch;\n  position: fixed;\n  top: 0;\n  right: -320px;\n}\n.drawer__icon {\n  z-index: 40;\n}\n.drawer__menu {\n  z-index: 30;\n}\n.drawer__overlay {\n  z-index: 20;\n}\n.drawer__checkbox:checked ~ .drawer__icon .drawer__icon.-parts {\n  background: transparent;\n}\n.drawer__checkbox:checked ~ .drawer__icon .drawer__icon.-parts:before {\n  -webkit-transform: rotate(-45deg);\n  transform: rotate(-45deg);\n  top: 0;\n}\n.drawer__checkbox:checked ~ .drawer__icon .drawer__icon.-parts:after {\n  -webkit-transform: rotate(45deg);\n  transform: rotate(45deg);\n  top: 0;\n}\n.drawer__checkbox:checked ~ .drawer__overlay {\n  opacity: 0.3;\n  pointer-events: auto;\n}\n.drawer__checkbox:checked ~ .drawer__menu {\n  right: 0;\n}\n.drawer__icon.-parts, .drawer__icon.-parts:after, .drawer__icon.-parts:before, .drawer__overlay, .drawer__menu {\n  -webkit-transition: all 0.7s;\n  transition: all 0.7s;\n}\n\n.sp {\n  display: none;\n}\n\n@media screen and (max-width: 768px) {\n  .sp {\n    display: block;\n  }\n}\n',
      }}
    />
  </div>
)
